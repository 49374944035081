import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  useTheme,
  CircularProgress,
  Theme,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import {
  CustomerActionContext,
  CustomerDataContext,
} from "../Context/CustomerContext";
import SiteContent from "../SiteContent/SiteContent";
import { IAppointment } from "../Context/Types";
import SelectionCard from "../Components/SelectionCard";
import ManageCollectedData from "../Components/ManageCollectedData";

export interface IProps {}

const useStyle = makeStyles((theme: Theme) => ({
  innerInput: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "21.79px",
  },
  timeSelectionWarnBox: {
    backgroundColor: theme.palette.warning.light,
  },
}));

const ManageAppointment: React.FC<IProps> = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyle();
  const defaultData = useContext(CustomerDataContext);
  const defaultAction = useContext(CustomerActionContext);
  const history = useHistory();
  const [loading, setloading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);

  function handleChange(propertyName: string, value: string) {
    defaultAction.setCustomerData({ ...defaultData });
  }

  return (
    <>
      {loading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item spacing={1}>
            <CircularProgress thickness={7} size={100} />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            container={true}
            item={true}
            direction="column"
            spacing={1}
            paddingLeft="80px"
            paddingRight="80px"
            marginTop="80px"
            flexBasis="72%"
          >
            <Grid item={true}>
              <Typography variant="h1">
                {SiteContent.ManageAppointment.title}
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography variant="sidebarRegular">
                {SiteContent.CustomerAppointment.subTitle}
              </Typography>
            </Grid>
            <Grid
              padding="20px"
              item={true}
              marginTop="20px"
              className={classes.timeSelectionWarnBox}
            >
              <Typography variant="sidebarRegular" style={{ color: "#000" }}>
                {SiteContent.CustomerAppointment.selectTime}
              </Typography>
            </Grid>
            <Grid container={true} item={true} spacing={1}>
              <Grid item={true}>
                <Typography variant="bodyBold">Service Date</Typography>
              </Grid>
              <Grid item={true} container={true}>
                <TextField
                  fullWidth
                  id="date"
                  type="date"
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >
                  ) => handleChange("date", event.target.value)}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      root: classes.innerInput,
                    },
                  }}
                />
              </Grid>
              <Grid item container flexDirection="column" spacing={2}>
                <Grid item={true}>
                  <Typography variant="bodyBold">Select a timeslot</Typography>
                </Grid>
                {/* {SiteContent.CustomerTimeZone.map((type, idx) => (
            <Grid item={true} key={idx}>
              <SelectionCard
                selected={defaultData.appointment.timeSlot === type}
                data={type}
                onClick={() => {
                  let appointment = {
                    ...defaultData.appointment,
                    timeSlot: type,
                  };
                  defaultAction.setCustomerData({ ...defaultData, appointment });
                }}
              />
            </Grid>
          ))} */}
              </Grid>

              <Grid
                item={true}
                container={true}
                style={{ marginTop: "16px" }}
                wrap="nowrap"
              >
                <Grid item={true} marginLeft="10px">
                  <Button
                    variant="main"
                    size="large"
                    style={{ backgroundColor: "#87A828" }}
                    onClick={() => {
                      history.push("/manage-booking/thank-you");
                    }}
                    disabled={btnDisabled}
                  >
                    <Typography variant="h2">
                      {" "}
                      {SiteContent.ManageAppointment.primaryButton}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item={true} marginTop="100px" flexBasis="28%">
            <ManageCollectedData />
          </Grid>
        </>
      )}
    </>
  );
};

export default ManageAppointment;
