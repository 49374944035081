import {
  Box,
  Grid,
  Typography,
  TextField,
  createStyles,
  Button,
  Theme,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { GetAppRounded } from "@mui/icons-material";
import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SiteActionContext, SiteDataContext } from "../Context/MeterContext";
import { StepActionContext, StepDataContext } from "../Context/StepperContext";
import SiteContent from "../SiteContent/SiteContent";
import { Steps } from "../Context/Types";

const useStyle = makeStyles((theme:Theme) =>
  ({
    innerInput: {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontSize: "16px",
      lineHeight: "21.79px",
    },
  })
);

const ManageWelcome: React.FC = () => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const history = useHistory();
  const siteData = useContext(SiteDataContext);
  const siteAction = useContext(SiteActionContext);
  const classes = useStyle();
  const stepperData = useContext(StepDataContext);
  const stepperAction = useContext(StepActionContext);

  function handleSiteID(siteID: string) {
    siteAction.setSiteDetail({ ...siteData, siteID });
    if (siteID.match(/([M][X][1][1][1][4][2][3][1][2])+/)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }

  useEffect(() => {
    handleSiteID(siteData.siteID);
  }, []);

  return (
    <Grid
      container={true}
      item={true}
      direction="column"
      spacing={1}
      paddingLeft="80px"
      paddingRight="80px"
      flexBasis="72%"
    >
      {/* <Grid> */}
      <Grid item={true}>
        <Typography variant="h1">{SiteContent.ManageBooking.title}</Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant="sidebarRegular">
          {SiteContent.ManageBooking.titleSmall}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant="sidebarRegular">
          {SiteContent.ManageBooking.titleSmallAppointment}{" "}
          <Box display="inline">
            <Typography
              variant="sidebarBold"
              color="primaryLink"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
            >
              {SiteContent.ManageBooking.titleSmallLink}
            </Typography>
          </Box>
        </Typography>
      </Grid>
      <Grid
        item={true}
        container={true}
        direction="column"
        spacing={1}
        marginTop="15px"
      >
        <Grid item={true}>
          <Typography variant="bodyBold">Confirmation Number</Typography>
        </Grid>
        <Grid item={true} container={true}>
          <TextField
            placeholder="Please enter your confirmation number"
            fullWidth
            variant="outlined"
            value={siteData.siteID}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => handleSiteID(event.target.value)}
            InputProps={{
              classes: {
                root: classes.innerInput,
              },
            }}
          />
        </Grid>
        <Grid item={true} marginTop="20px">
          <Button
            variant="main"
            disabled={!isValid}
            onClick={() => {
              stepperAction.setActiveStep(Steps.customerInformation);
              stepperAction.setCompletedSteps([
                ...stepperData.completedSteps,
                Steps.welcome,
              ]);
              history.push("/manage-booking/customer-appointment");
            }}
          >
            <Typography variant="h2">
              {SiteContent.ManageBooking.primaryButton}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManageWelcome;
