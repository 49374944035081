const siteContent = {
  welcome: {
    title:"SCHEDULE YOUR METER EXCHANGE",
    titleSmall:"To get started, please enter your site ID. You should find it on your Meter Exchange Letter.",
    titleSmallAppointment:"Already have an appointment?",
    titleSmallLink:"Manage your existing booking.",
    primaryButton:"GET STARTED"
  },
  CustomerInformation:{
    title:"CUSTOMER AND METER EXCHANGE APPOINTMENT INFORMATION",
    subTitle:"Please provide the following information.",
    primaryButton:"Next",
    secondaryButton:"Back",
    submitButton: "Submit"
  },
  CustomerTimeZone: ["7:30 am - 9:30 am","9:30 am - 11:30 am","11:30 am - 1:30 pm","1:30 pm - 3:30 pm","5:30 pm - 7:30 pm"],
  CustomerAppointment:{
    title:"SCHEDULE YOUR APPOINTMENT",
    subTitle:"Available appointments are scheduled within a two hour window. An ATCO Gas service person will arrive within the selected timeframe. Your duration may vary based on the services being installed or repaired.",
    selectTime:"Please select a time when you are available as our technician will need to enter your house to complete the relight.",
    reminders:{
      email:"Email me a reminder 2 days before my appointment.",
      call:"Call me when you are on your way."
    },
    primaryButton:"Submit",
    secondaryButton:"Back"
  },
  Thankyou:{
    title:"THANK YOU! YOUR APPOINTMENT IS CONFIRMED!",
    subTitle:"A copy of your request has been sent to your email."
  },
  ManageBooking:{
    title:'MANAGE YOUR BOOKING',
    titleSmall:"To manage your existing booking, please enter your email address",
    titleSmallAppointment:"Don’t have an appointment?",
    titleSmallLink:"Schedule a Meter Exchange",
    primaryButton:"VIEW MY BOOKING"
  },
  ManageAppointment:{
    title:"YOUR EXISTING APPOINTMENT",
    primaryButton:'UPDATE APPOINTMENT'
  }
};

export default siteContent;
