export interface ICustomerData {
  customerDetails: ICustomerDetails;
  appointment: IAppointment;
  reminders: IReminders;
}

export interface IAppointmentBookRequest {
  taskType: string;
  startDateTime: string;
  postal: string;
  source: string;
  endDateTime: string;
  spId: string;
}

export interface IGetSiteID {
  SiteID: string;
}

/////

////
export interface IMakeBookingRequest {
  SiteID: string;
  confirmationCode: string;
  appointmentBookRequest: IAppointmentBookRequest;
  customerDetails: ICustomerDetails;
  appointment: IAppointmentList;
  reminders: IReminders;
}
export interface ICustomerDetails {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  alternatePhoneNumber: string;
  isDog: string;
  isRelightRequired: string;
}

export interface IFormState {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  alternatePhoneNumber: string;
  isDog: string;
  isRelightRequired: string;
}
export interface IReminders {
  isCall: boolean;
  isEmail: boolean;
}
export interface IAppointment {
  date: string;
  time: string;
  timeSlot: string;
}
export interface IDefaultCustomerData {
  customerDetails: ICustomerDetails;
  confirmationId: string;
  taskType: string;
  siteId: string;
  spId: string;
  postal: string;
  address: IAddress;
  appointment: IAppointment;
}

export interface IDefaultIdResponseData {
  SiteID: string;
  taskType: string;
  spId: string;
  postal: string;
  SOStatus: string;
  errMsg: string;
  errMsgCd: string;
}

export interface IAddress {
  address: string;
  meterLocation: string;
}
export interface ISiteSearchByIdResult {
  data: {
    SiteID: string;
    taskType: string;
    spId: string;
    postal: string;
    SOStatus: string;
    errMsg: string;
    errMsgCd: string;
  };
  success: boolean;
}

export interface ISiteIdData {
  id: string;
  error: boolean;
  valid: boolean;
}

export interface IAppointmentRequest {
  taskType: string;
  startDateTime: string;
  postal: string;
  source: string;
  endDateTime: string;
  spId: string;
}

export interface IAppointmentResponse {
  data: {
    responseDetail: { appointmentBookingsList: IAppointmentList[] };
  };
  success: boolean;
}

export interface IAppointmentList {
  appointmentWindowDateTime: string;
  appointmentWindowDuration: string;
  arrivalDateTime: string;
  completionDateTime: string;
  rank: string;
  shiftId: string;
  units: string;
}

export interface IAppointmentBookingData {
  comments: string;
  completionDateTime: string;
  siteId: string;
  arrivalDateTime: string;
  appointmentWindowDateTime: string;
  appointmentWindowDuration: string;
}

export interface IDayTimeMatrix {
  day: string;
  appointments: IAppointmentList[];
}

export enum Steps {
  welcome = "Welcome",
  customerInformation = "Customer Information",
  appointment = "Customer Appointment",
  thankyou = "Thank You",
}

export interface IFinalSubmit {
  emailData: IMakeBookingRequest;
  apointmentBookingData: IAppointmentBookingData;
}
