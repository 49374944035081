import { Box, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';


import React from 'react';
import { IAppointmentList } from '../Context/Types';
import useReadableTime from '../Hooks/useReadableTime';

interface IProps {
  title?: string;
  data: IAppointmentList;
  selected: boolean;
  onClick: (data: IAppointmentList | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  ({
    root: {
      
      width: "100%",
      fontColor: theme.palette.primary.main,
      padding: theme.spacing(1),
      border: "1px solid #DDDDDD",
      backgroundColor: "#FFFFFF",
      borderRadius: "4px",
      "&:hover": {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primaryHover,
        color: theme.palette.primary.main,
      },
    },
    innerBoxPadding: {
      display:'flex',
      width:'100%',
      padding: theme.spacing(1),
    },
    selected: {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primaryHover,
      color: theme.palette.primary.main,
    },
  })
);

const SelectionCard: React.FC<IProps> = (props: IProps) => {
  const classes = useStyles();
  const { returnReadableTime } = useReadableTime();
  const theme = useTheme();

  const convert24to12Hourtime =(time : any)=> {
    // Check correct time format and split into components
    let displayTime = '';
    if (time!=null) { // If time format correct
      const hours = Number(time.substr(0,2));
      const minutes = time.substr(3,2);
      if( hours>12){
        const newHours = hours-12;
        displayTime = newHours + ":"+minutes+" PM"
        return (displayTime )
      }else{
        displayTime = hours + ":" + minutes+" AM"
      }
    }
    return displayTime; // return adjusted time or original string
  }

  return (
    <Box
      className={classes.root}
      style={{
        cursor: "pointer",
        borderColor: props.selected ? theme.palette.primary.main : "",
        backgroundColor: props.selected ? theme.palette.primaryHover : "",
        color: props.selected ? theme.palette.primary.main : "",
      }}
    >
      <Box
        className={classes.innerBoxPadding}
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={() => props.onClick(props.data)}
      >
        
         <Typography variant="bodyBold">{returnReadableTime(props.data.arrivalDateTime.substr(-8,5))}  </Typography>
         <Typography sx={{padding:"4px"}}> - </Typography>
        <Typography variant="bodyBold"> {returnReadableTime(props.data.completionDateTime.substr(-8,5))}</Typography>
      </Box>
    </Box>
  );
};

export default SelectionCard;
