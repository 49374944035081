import React, { useContext } from "react";
import {
  Grid,
  Typography,
  Button,
  Box
} from "@mui/material";
import { useHistory } from "react-router-dom";
import PrintIcon from '@mui/icons-material/Print';
import {
  CustomerDataContext
} from "../Context/CustomerContext";
import useResponsiveHelper from '../Hooks/useResponsiveHelper';
import SiteContent from "../SiteContent/SiteContent";
import { makeStyles } from '@mui/styles';
import moment from "moment";
import useLoading from "../Hooks/useLoading";
import useReadableTime from "../Hooks/useReadableTime";


const useStyle = makeStyles((theme) => ({
  root: {
      marginTop: '32px',
      display: 'flex',
      flexDirection: 'column',
      spacing: '8px',
      [theme.breakpoints.only('desktop')]: {
          paddingLeft: '80px',
          paddingRight: '80px',
      },
      [theme.breakpoints.only('tablet')]: {
          paddingLeft: '40px',
          paddingRight: '40px',
      },
      [theme.breakpoints.only('mobile')]: {
          paddingLeft: '32px',
          paddingRight: '32px',
      },
  },
  titleAndPrintButton: {
      [theme.breakpoints.up('tablet')]: {
          display: 'flex',
          justifyContent: 'space-between',
      },
      [theme.breakpoints.down('tablet')]: {
          display: 'flex',
          flexDirection: 'column',
      },
  },
  clientInfoDisplay: {
      marginTop: '10px',
      [theme.breakpoints.up('tablet')]: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
      },
      [theme.breakpoints.down('tablet')]: {
          display: 'flex',
          flexDirection: 'column',
      },
  },
  printButton: {
      display: 'flex',
      justifyContent: 'center',

      [theme.breakpoints.up('tablet')]: {
          paddingBottom: '0px',
          paddingTop: '0px',
      },
      [theme.breakpoints.down('tablet')]: {
          paddingBottom: '16px',
          paddingTop: '16px',
      },
  },
}));


const Thankyou: React.FC = () => {
  const customerData = useContext(CustomerDataContext);
  const { isMobile, isTabletOrDesktop } = useResponsiveHelper();
  const { returnReadableTime } = useReadableTime();
  const history = useHistory();
  const classes = useStyle();
  const { loading } = useLoading();


  const convertBoolToYesNo=(bool:boolean):string=>{
    if(bool ===true){
      return("Yes")
    }
    else{return("No")}
  }

  return (
    <>
          {loading !== null && loading}
            {loading === null && (
           <Grid container={true} item={true} className={classes.root}>
           <Grid item={true}>

               <Box className={classes.titleAndPrintButton}>
                   {isTabletOrDesktop && (
                       <Box>
                           <Typography variant="h1">{SiteContent.Thankyou.title}</Typography>
                       </Box>
                   )}
                   {isMobile && (
                       <Box>
                           <Box>
                               <Typography variant="h1">THANK YOU!</Typography>
                           </Box>
                           <Box>
                               <Typography variant="h1">YOUR APPOINTMENT IS CONFIRMED!</Typography>
                           </Box>
                       </Box>
                   )}
                   {/* <Box className={classes.printButton}>
                       <Button
                           endIcon={<PrintIcon />}
                           variant="submain"
                           size="large"
                           onClick={() => {
                               window.print();
                           }}
                       >
                           <Typography variant="h2">Print</Typography>
                       </Button>
                   </Box> */}
               </Box>
               {/* )} */}
           </Grid>
           <Grid item={true}>
               <Typography variant="sidebarRegular">{SiteContent.Thankyou.subTitle}</Typography>
           </Grid>
            
            <Grid marginTop="20px">
            <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">Confirmation Number</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">{customerData.confirmationCode}</Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">Arrival Time</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    {" "}
                    {moment(customerData.appointment.arrivalDateTime.toString()).format("LL") +
                     " between " +
                     returnReadableTime(customerData.appointment.arrivalDateTime.substr(-8,5))
                     + " and " +
                     returnReadableTime(customerData.appointment.completionDateTime.substr(-8,5))
                     }
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">Appointment Type</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">Meter Replacement</Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">Site ID</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">{customerData.SiteID}</Typography>
                </Grid>
              </Grid>
              {/* <Grid
                container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                marginTop="10px"
              >
                <Grid item={true}>
                  <Typography variant="bodyRegular">Service Address</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    where do we get address from?
                  </Typography>
                </Grid>
              </Grid> */}
              {/* <Grid
                container
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                marginTop="10px"
              >
                <Grid item={true}>
                  <Typography variant="bodyRegular">Meter Location</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    where do we get Meter Location?
                  </Typography>
                </Grid>
              </Grid> */}
               <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">First Name</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    {customerData.customerDetails.firstName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">Last Name</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    {customerData.customerDetails.lastName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">Phone Number</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    {customerData.customerDetails.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">
                    Alternate Phone Number
            </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    {customerData.customerDetails.alternatePhoneNumber || 'Null'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">Email Address</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    {customerData.customerDetails.email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">
                      Is There A Dog In The Yard/ On The Property?
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    {customerData.customerDetails.isDog.toString()}
                    </Typography>
                </Grid>
                </Grid>
                <Grid container className={classes.clientInfoDisplay}>
              <Grid item={true}>
                  <Typography variant="responsiveBodyText">
                     Relight Required?
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    {customerData.customerDetails.isRelightRequired}
                    </Typography>
                </Grid>
             
                <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">Email Reminder?</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    {convertBoolToYesNo(customerData.reminders.isEmail)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.clientInfoDisplay}>
                <Grid item={true}>
                  <Typography variant="responsiveBodyText">Phone Call Reminder?</Typography>
                </Grid>
                <Grid item={true}>
                  <Typography variant="bodyRegular">
                    {convertBoolToYesNo(customerData.reminders.isCall)}
                  </Typography>
                </Grid>
              </Grid>
              </Grid>
              
              
              <Grid
                item={true}
                container={true}
                justifyContent="flex-start"
                style={{ marginTop: "30px" }}
                wrap="nowrap">
               <Box sx={{paddingRight:'20px'}}>
                  <Button
                    variant="submain"
                    size="large"
                    onClick={() => {
                      window.print();
                  }}
                  >
                    <Typography variant="h2">
                      Print a Copy
                     </Typography>
                    </Button>
                </Box>
                <Box>
                    <Button
                          variant="main"
                          size="large"
                          onClick={() => {
                          history.push('/');
                                  }}
                        >
                        <Typography variant="h2"> Return to Main </Typography>
                      </Button>
                  </Box>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </>
  );
};

export default Thankyou;