import React, { createContext } from "react";
import { Steps } from "./Types";

interface IStepAction {
  setActiveStep: React.Dispatch<React.SetStateAction<string>>;
  setCompletedSteps: React.Dispatch<React.SetStateAction<string[]>>;
}

interface IStepData {
  activeStep: string;
  completedSteps: string[];
}

interface IProps {
  children?: React.ReactNode;
}

const StepActionContext = createContext<IStepAction>({
  setActiveStep: React.useState,
  setCompletedSteps: React.useState,
});

const StepDataContext = createContext<IStepData>({
  activeStep: "",
  completedSteps: [],
});

const StepperProvider: React.FC<IProps> = (props: IProps) => {
  const [activeStep, setActiveStep] = React.useState<string>(Steps.welcome);
  const [completedSteps, setCompletedSteps] = React.useState<string[]>([]);

  return (
    <StepActionContext.Provider
      value={{
        setActiveStep,
        setCompletedSteps,
      }}
    >
      <StepDataContext.Provider
        value={{
          activeStep,
          completedSteps,
        }}
      >
        {props.children}
      </StepDataContext.Provider>
    </StepActionContext.Provider>
  );
};
export { StepActionContext, StepDataContext };
export default StepperProvider;
