import { Grid,  TextField, Typography, Theme,  MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { ICustomerDetails } from '../Context/Types';
import SiteContent from '../SiteContent/SiteContent';

interface IProps {
    values: ICustomerDetails;
    handleChange: (e: any) => void;
    handleBlur: (e: any) => void;
    errors: any;
    touched: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexDirection: 'column',
        marginTop: theme.spacing(10),
        flexBasis: '72%',
        height: '100%',
    },
    innerInput: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: '21.79px',
    },
}));

const CustomerForm = (props: IProps) => {
    const { values, errors, touched, handleChange, handleBlur } = props;
    const classes = useStyles();
    return (
        <Grid container={true} item={true} className={classes.container}>
            <Grid item={true}>
                <Typography variant="h1">{SiteContent.CustomerInformation.title}</Typography>
            </Grid>
            <Grid item={true}>
                <Typography variant="sidebarRegular">{SiteContent.CustomerInformation.subTitle}</Typography>
            </Grid>

            <Grid item={true} container={true} direction="column" spacing={1} sx={{ mt: 2 }}>
                <Grid container={true} item={true} spacing={1}>
                    <Grid item={true}>
                        <Typography variant="bodyBold">First Name</Typography>
                    </Grid>
                    <Grid item={true} container={true}>
                        <TextField
                            placeholder="Please enter your first name"
                            variant="outlined"
                            name="firstName"
                            value={values.firstName}
                            error={!!errors.firstName && !!touched.firstName}
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                <Typography variant="smallText" color="red">
                                    {touched.firstName ? errors.firstName : ''}
                                </Typography>
                            }
                            InputProps={{
                                classes: {
                                    root: classes.innerInput,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container={true} item={true} spacing={1}>
                    <Grid item={true}>
                        <Typography variant="bodyBold">Last Name</Typography>
                    </Grid>
                    <Grid item={true} container={true}>
                        <TextField
                            placeholder="Please enter in your last name"
                            variant="outlined"
                            name="lastName"
                            value={values.lastName}
                            error={!!errors.lastName && !!touched.lastName}
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                <Typography variant="smallText" color="red">
                                    {touched.lastName ? errors.lastName : ''}
                                </Typography>
                            }
                            InputProps={{
                                classes: {
                                    root: classes.innerInput,
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                <Grid container={true} item={true} marginTop="10px">
                    <Grid item={true}>
                        <Typography variant="bodyBold">Phone Number</Typography>
                    </Grid>
                    <Grid item={true} container={true} flexDirection="column">
                        <TextField
                            placeholder="Please enter your phone number"
                            name="phoneNumber"
                            error={!!errors.phoneNumber && !!touched.phoneNumber}
                            fullWidth
                            value={values.phoneNumber}
                            variant="outlined"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={
                                <Typography variant="smallText" color="red">
                                    {touched.phoneNumber ? errors.phoneNumber : ''}
                                </Typography>
                            }
                            InputProps={{
                                classes: {
                                    root: classes.innerInput,
                                },
                            }}
                        />
                        <Typography variant="smallText">ATCO Gas will only attempt to call this number if there is a problem with the appointment.</Typography>
                    </Grid>
                </Grid>

                <Grid container={true} item={true} spacing={1} sx={{ mt: 2 }}>
                    <Grid item={true}>
                        <Typography variant="bodyBold">Alternate Phone Number</Typography>
                    </Grid>
                    <Grid item={true} container={true}>
                        <TextField
                            placeholder="Please enter an alternative phone number"
                            name="alternatePhoneNumber"
                            value={values.alternatePhoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            variant="outlined"
                            helperText={
                                <Typography variant="smallText" color="red">
                                    {touched.alternatePhoneNumber ? errors.alternatePhoneNumber : ''}
                                </Typography>
                            }
                            InputProps={{
                                classes: {
                                    root: classes.innerInput,
                                },
                            }}
                        />
                        <Typography variant="smallText">Optional</Typography>
                    </Grid>
                </Grid>

                <Grid container={true} item={true} spacing={1} marginTop="10px">
                    <Grid item={true}>
                        <Typography variant="bodyBold">Email Address</Typography>
                    </Grid>
                    <Grid item={true} container={true}>
                        <TextField
                            placeholder="Please enter your email address"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            error={!!errors.email && !!touched.email}
                            onBlur={handleBlur}
                            helperText={
                                <Typography variant="smallText" color="red">
                                    {touched.email ? errors.email : ''}
                                </Typography>
                            }
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    root: classes.innerInput,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container={true} item={true} spacing={1} marginTop="10px">
                    <Grid item={true}>
                        <Typography variant="bodyBold">
                        Is there a dog in the yard/on the property?
                        </Typography>
                  </Grid>
                  <Grid item={true} container={true}>
                    <TextField
                    value={values.isDog}
                    name='isDog'
                    placeholder="Please select"
                    error={!!errors.isDog && !!touched.isDog}
                    fullWidth
                    onChange={handleChange}
                    variant="outlined"
                    select
                    helperText={
                        <Typography variant="smallText" color="red">
                            {touched.isDog ? errors.isDog : ''}
                        </Typography>
                    }
                    InputProps={{
                        classes: {
                        root: classes.innerInput,
                        },
                    }}
                    >
                        {["Yes", "No"].map((item, idx) => (
                            <MenuItem value={item} key={idx}>
                             <Typography variant="bodyRegular">{item}</Typography>
                            </MenuItem>
                        ))}
                        </TextField>
                        {!values.isDog &&
                        values.isDog && (
                            <Grid item={true}>
                                <Typography variant="smallText" color="red">
                                   error
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
             </Grid>
        

        <Grid container={true} item={true} spacing={1} marginTop="10px">
          <Grid item={true}>
            <Typography variant="bodyBold">Need a relight?</Typography>
          </Grid>
          <Grid item={true} container={true}>
            <TextField
            name='isRelightRequired'
              value={values.isRelightRequired}
              placeholder="Please select"
              error={!!errors.isRelightRequired && !!touched.isRelightRequired}
              fullWidth
              onChange={handleChange}
              variant="outlined"
              select
              helperText={
                <Typography variant="smallText" color="red">
                    {touched.isRelightRequired ? errors.isRelightRequired : ''}
                </Typography>
            }
              InputProps={{
                classes: {
                  root: classes.innerInput,
                },
              }}
            >
              {["Yes", "No"].map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  <Typography variant="bodyRegular">{item}</Typography>
                </MenuItem>
              ))}
            </TextField>
            {!values.isRelightRequired &&
              values.isRelightRequired && (
                <Grid item={true}>
                  <Typography variant="smallText" color="red">
                    {values.isRelightRequired}
                  </Typography>
                </Grid>
              )}
            <Typography variant="smallText">
              You may need to re-light your natural gas appliances after a
              disruption in service. It's important to know which of your
              appliances have a pilot light. Many furnaces, garage heaters,
              ranges, gas clothes dryers, and newer water heaters have
              electronic ignition, not a pilot light, and will not need to be
              relit.
            </Typography>
          </Grid>
        </Grid>
           
        </Grid>
        </Grid>
    );
};

export default CustomerForm;
