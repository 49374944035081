import React from "react";
import { httpsCallable, HttpsCallableResult } from "firebase/functions";
import {
  IAppointmentBookingData,
  IAppointmentBookRequest,
  IAppointmentResponse,
  IDefaultIdResponseData,
  IFinalSubmit,
  IMakeBookingRequest,
  ISiteSearchByIdResult,
} from "../Context/Types";
import {} from "../API/InspectionDefaults";
import { functions } from "../API/firebase";
import axios from "axios";

const BookAppointment = httpsCallable(functions, "bookAppointment", {
  timeout: 200000,
});
const SearchSiteId = httpsCallable(functions, "checkSiteIdStatus", {
  timeout: 200000,
});
const SearchAppointment = httpsCallable(functions, "appointmentTimes", {
  timeout: 200000,
});

const useHomeInspection = () => {
  const bookApppointment = async (submitData: IFinalSubmit): Promise<void> => {
    console.log(submitData);
    const sendData = await BookAppointment(submitData);
    const parsedData = JSON.parse(JSON.stringify(sendData.data));
  };

  const searchSiteId = async (
    SiteID: string
  ): Promise<ISiteSearchByIdResult> => {
    const siteSearchResults = await SearchSiteId({ GetStatus: { SiteID } });
    const parsedData = JSON.parse(JSON.stringify(siteSearchResults.data));

    return parsedData as ISiteSearchByIdResult;
  };

  const searchAppointment = async (
    appointmentRequest: IAppointmentBookRequest
  ): Promise<IAppointmentResponse> => {
    const searchedAppointments = await SearchAppointment({
      sendDetail: appointmentRequest,
    });
    const parsedData = JSON.parse(JSON.stringify(searchedAppointments.data));

    return parsedData;
  };
  // return { bookApppointment, searchSiteId, searchAppointment } as const;
  return { searchSiteId, searchAppointment, bookApppointment } as const;
};

export default useHomeInspection;
