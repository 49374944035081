import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  Checkbox,
  Theme,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import {
  CustomerActionContext,
  CustomerDataContext,
} from "../Context/CustomerContext";
import SiteContent from "../SiteContent/SiteContent";
import {
  IAppointment,
  IAppointmentBookingData,
  IAppointmentBookRequest,
  IAppointmentList,
  ICustomerData,
  ICustomerDetails,
  IDayTimeMatrix,
  IFinalSubmit,
} from "../Context/Types";
import CollectedData from "../Components/CollectedData";
import { makeStyles } from "@mui/styles";
import useHomeInspection from "../Hooks/useHomeInspection";
import useLoading from "../Hooks/useLoading";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import AppointmentGrid from "../Components/AppointmentGrid";
import useResponsiveHelper from "../Hooks/useResponsiveHelper";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { firebaseAnalytics, firebaseLogEvent } from "../API/firebase";

export interface IProps {}

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    paddingLeft: "0px",
    marginTop: "100px",
    [theme.breakpoints.only("tablet")]: {
      display: "flex",
      flexDirection: "column",
      paddingRight: "60px",
      paddingLeft: "60px",
    },
    [theme.breakpoints.only("mobile")]: {
      display: "flex",
      flexDirection: "column",
      paddingRight: "40px",
      paddingLeft: "40px",
    },
  },
  leftColumn: {
    [theme.breakpoints.only("desktop")]: {
      paddingLeft: "100px",
      paddingRight: "20px",
    },
    [theme.breakpoints.down("desktop")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  subTitle: {
    paddingRight: "20%",
    [theme.breakpoints.only("mobile")]: {
      paddingRight: "0px",
    },
  },
  selectDay: {
    width: "180px",
    paddingBottom: "16px",
    [theme.breakpoints.only("mobile")]: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      paddingRight: "0px",
    },
  },
  appointmentGrid: {
    display: "flex",
    flexDirection: "column",
    spacing: "8px",
    flexBasis: "72%",

    [theme.breakpoints.only("desktop")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.only("tablet")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    [theme.breakpoints.only("mobile")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  customerInformationSummary: {
    marginLeft: "50px",
    flexBasis: "28%",
    paddingRight: "32px",
  },
  dateAndOtherInfoGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  innerInput: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "21.79px",
  },
  timeSelectionWarnBox: {
    backgroundColor: theme.palette.warning.light,
  },
  datePicker: {
    "& .MuiOutlinedInput-root": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontSize: "16px",
      width: "100%",
    },
    "& .MuiTextField-root": {
      minWidth: "100%",
    },
  },
}));

const Appointment: React.FC<IProps> = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyle();
  const data = useContext(CustomerDataContext);
  const actions = useContext(CustomerActionContext);
  const history = useHistory();
  const { showLoading, hideLoading, loading } = useLoading();
  const { pathname } = useLocation();
  const { isMobileOrTablet, isDesktop } = useResponsiveHelper();
  const { searchAppointment, bookApppointment } = useHomeInspection();
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [appointmentList, setAppointmentList] = useState<IAppointmentList[]>(
    []
  );
  const [availableTimes, setAvailableTimes] = useState<IDayTimeMatrix[]>([]);
  const [uniqueID, setUniqueID] = useState<string>("");
  const [nullSubmit, setNullSubmit] = useState<boolean>(false);
  const [appointmentApiCallError, setAppointmentApiCallError] = useState<{
    error: boolean;
    message: string;
  }>({ error: false, message: "select a date" });
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [acceptedRecaptcha, setaccpetedRecaptcha] = useState<boolean>();

  const minDate = new Date(new Date().getTime() + 86400000);
  const maxDate = new Date(new Date().getTime() + 86400000 * 120);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const convertDateTimeForAPICall = (str: string): string => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const formatDate = (str: string): string => {
    //re-arranging the string, to match the data needed for the API call

    return str.replace(
      /\w+ (\w+) (\d+) (\d+) (\d+):(\d+).*/,
      (_, M, d, y, h, m) =>
        `${y}-${
          1 + "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(M) / 3
        }-${d}`.replace(/\b\d\//, "0$&")
    );
  };

  const addDaysToDate = (value: string, daysToAdd: number): Date => {
    const newDate = new Date(value);
    const endDate = new Date(
      newDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000
    );
    return endDate;
  };

  const createConfirmationCode = () => {
    const uniqueID = Math.floor(Date.now() / 1000);
    setUniqueID(uniqueID.toString());
  };

  const onChangeHandle = async (calenderDate: any) => {
    let incriment = 0;
    let loops = 0;
    // number of weeks to loop through, 6 = 30 days
    let maxLoops = 18;

    while (loops < maxLoops) {
      let formatedDate = formatDate(
        addDaysToDate(calenderDate, incriment) + ""
      );
      // number of days to loop through, 5 = 1 week
      const success = await getAppointements("date", formatedDate, 5);
      setSelectedDate(calenderDate);
      loops++;
      incriment = incriment + 5;
      if (success === true) {
        break;
      }
      if (loops === maxLoops) {
        firebaseLogEvent(firebaseAnalytics, "no_appointments_found");
      }
    }

    createConfirmationCode();
  };

  const mappedtimes = (unfilteredTimes: IAppointmentList[]) => {
    const filteredStartTimes = unfilteredTimes.map((times) => {
      const starttime = times.arrivalDateTime.substring(0, 10); // get a list of times can be duplicates
      return starttime;
    });
    const uniqueStartTimes = [...Array.from(new Set(filteredStartTimes))]; // casting anything to set will remove duplicates and only unique values
    const mappedTimes = uniqueStartTimes.map((startTime) => {
      const mappedTime = {
        day: startTime,
        appointments: unfilteredTimes.filter((time) =>
          time.arrivalDateTime.includes(startTime)
        ),
      } as IDayTimeMatrix;
      return mappedTime;
    });
    return mappedTimes;
  };

  const getAppointements = async (
    propertyName: string,
    value: string,
    dayRange: number
  ): Promise<boolean | void> => {
    const appointment = {
      ...data.appointment,
      [propertyName as keyof IAppointment]: value,
    } as IAppointmentList;

    const endDate = addDaysToDate(value, dayRange);

    const appointmentData = {
      ...data.appointmentBookRequest,
      startDateTime:
        convertDateTimeForAPICall(formatDate(value)) + "T12:00:00-07:00",
      endDateTime:
        convertDateTimeForAPICall(endDate.toString()) + "T14:00:00-07:00",
    } as IAppointmentBookRequest;

    try {
      showLoading();
      const returnedAppointments = await searchAppointment(appointmentData);
      if (
        returnedAppointments.data.responseDetail.appointmentBookingsList
          .length > 0
      ) {
        setAppointmentList(
          returnedAppointments.data.responseDetail.appointmentBookingsList
        );
        setAvailableTimes(
          mappedtimes(
            returnedAppointments.data.responseDetail.appointmentBookingsList
          )
        );
        setAppointmentApiCallError({ message: "success", error: false });
        firebaseLogEvent(firebaseAnalytics, "appointments_found");
        return true;
      } else {
        setAppointmentApiCallError({
          message: "no appointments were found within 90 days",
          error: true,
        });
        return false;
      }
    } catch (error) {
      setAppointmentApiCallError({
        message:
          "no appointments were found within 90 days of your request, feel free to call us at 310-5678",
        error: true,
      });
    } finally {
      hideLoading();
    }
    actions.setCustomerData({ ...data, appointment });
  };

  return (
    <>
      {loading !== null && loading}
      {loading === null && (
        <>
          <Box className={classes.root}>
            <Box>
              <Box className={classes.leftColumn}>
                <Box>
                  <Typography variant="h1">
                    {SiteContent.CustomerAppointment.title}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="sidebarRegular">
                    {SiteContent.CustomerAppointment.subTitle}
                  </Typography>
                </Box>
                {data.customerDetails.isRelightRequired === "Yes" ? (
                  <Box
                    padding="20px"
                    marginTop="20px"
                    className={classes.timeSelectionWarnBox}
                  >
                    <Typography
                      variant="sidebarRegular"
                      style={{ color: "#000" }}
                    >
                      {SiteContent.CustomerAppointment.selectTime}
                    </Typography>
                  </Box>
                ) : null}
                <Box sx={{ width: "100%", marginTop: "32px" }}>
                  <Box>
                    <Typography variant="bodyBold">Service Date</Typography>
                  </Box>

                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Box className={classes.datePicker}>
                        <DatePicker
                          inputFormat="MM/dd/yyyy"
                          value={selectedDate}
                          minDate={minDate}
                          maxDate={maxDate}
                          disablePast
                          onChange={async (selectedDate) =>
                            onChangeHandle(selectedDate)
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Box>
                    </LocalizationProvider>
                  </Box>

                  <Box>
                    {appointmentApiCallError.error && (
                      <Box sx={{ paddingLeft: "0px" }}>
                        <Typography variant="caption" color="red">
                          {appointmentApiCallError.message}
                        </Typography>
                      </Box>
                    )}
                    {!appointmentApiCallError.error &&
                      appointmentList.length > 0 && (
                        <Box marginTop="32px" width="100%">
                          <Box>
                            <Typography variant="bodyBold">
                              Select a timeslot
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="bodyRegular">
                              We found these appointment options closest to the
                              requested date:
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            {availableTimes.map((time) => (
                              <AppointmentGrid
                                data={time}
                                uniqueID={uniqueID}
                              />
                            ))}
                          </Box>
                        </Box>
                      )}
                  </Box>
                  <Box
                    paddingLeft="0px"
                    flexDirection="column"
                    marginTop="32px"
                  >
                    <Box>
                      <Typography variant="bodyBold">Reminders</Typography>
                    </Box>
                    <Box flexDirection="column">
                      <Checkbox
                        checked={data.reminders.isEmail}
                        color="primary"
                        onClick={() => {
                          let reminders = {
                            ...data.reminders,
                            isEmail: !data.reminders.isEmail,
                          };
                          actions.setCustomerData({
                            ...data,
                            reminders,
                          });
                        }}
                      />
                      <Box display="inline">
                        <Typography variant="sidebarRegular">
                          {SiteContent.CustomerAppointment.reminders.email}
                        </Typography>
                      </Box>
                    </Box>
                    <Box flexDirection="column">
                      <Checkbox
                        checked={data.reminders.isCall}
                        color="primary"
                        onClick={() => {
                          let reminders = {
                            ...data.reminders,
                            isCall: !data.reminders.isCall,
                          };
                          actions.setCustomerData({
                            ...data,
                            reminders,
                          });
                        }}
                      />
                      <Box display="inline">
                        <Typography variant="sidebarRegular">
                          {SiteContent.CustomerAppointment.reminders.call}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: "30px",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Checkbox
                        checked={acceptedRecaptcha}
                        color="primary"
                        //onClick={handleReCaptchaVerify}
                        onClick={async () => {
                          let token = "";
                          if (executeRecaptcha) {
                            token = await executeRecaptcha();
                            if (token !== "") {
                              console.log("token: ", token);
                              setaccpetedRecaptcha(!acceptedRecaptcha);
                            }
                          }
                        }}
                      />
                    </Box>

                    <Box>
                      <Typography variant="sidebarRegular">
                        I'm not a robot{" "}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      justifyContent: "flex-start",
                      marginTop: "0px",
                      paddingLeft: "16px",
                    }}
                  >
                    <Typography variant="smallText">
                      This site is protected by reCAPTCHA and the Google{" "}
                      <a href="https://policies.google.com/privacy">
                        Privacy Policy
                      </a>
                    </Typography>
                    <Typography variant="smallText">
                      ,{" "}
                      <a href="https://policies.google.com/terms">
                        Terms of Service
                      </a>{" "}
                      apply.
                    </Typography>
                  </Box>

                  <Box>
                    {isMobileOrTablet && (
                      <Box sx={{ marginTop: "40px" }}>
                        <Box>
                          <Typography variant="bodyBold">
                            {" "}
                            Other Info
                          </Typography>
                        </Box>
                        <Box marginRight="0px">
                          <CollectedData />
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ marginTop: "30px", display: "flex" }}>
                    <Box>
                      <Button
                        variant="submain"
                        size="large"
                        onClick={() => {
                          history.push("/meter-exchange/customer-information");
                        }}
                      >
                        <Typography variant="h2">Back</Typography>
                      </Button>
                    </Box>
                    <Box marginLeft="10px">
                      <Button
                        variant="main"
                        size="large"
                        disabled={false}
                        onClick={() => {
                          if (
                            data.appointment.arrivalDateTime !== "" &&
                            acceptedRecaptcha === true
                          ) {
                            const apointmentBookingData = {
                              comments:
                                JSON.stringify(data.customerDetails) +
                                JSON.stringify(data.reminders),
                              completionDateTime:
                                data.appointment.completionDateTime,
                              siteId: data.SiteID,
                              arrivalDateTime: data.appointment.arrivalDateTime,
                              appointmentWindowDateTime:
                                data.appointment.appointmentWindowDateTime,
                              appointmentWindowDuration:
                                data.appointment.appointmentWindowDuration,
                            } as IAppointmentBookingData;

                            const submitData = {
                              apointmentBookingData: apointmentBookingData,
                              emailData: data,
                            } as IFinalSubmit;
                            setNullSubmit(false);
                            bookApppointment(submitData);
                            firebaseLogEvent(
                              firebaseAnalytics,
                              "thankyou_page_visited"
                            );
                            history.push("/meter-exchange/thank-you");
                          } else if (acceptedRecaptcha !== true) {
                            setaccpetedRecaptcha(false);
                          } else {
                            setNullSubmit(true);
                            setaccpetedRecaptcha(false);
                          }
                        }}
                        style={{ backgroundColor: "#008000" }}
                      >
                        <Typography variant="h2">
                          {" "}
                          {SiteContent.CustomerInformation.submitButton}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                  {nullSubmit && (
                    <Box>
                      <Typography variant="smallText" color="red">
                        Please select an available time
                      </Typography>
                    </Box>
                  )}
                  {!acceptedRecaptcha && (
                    <Box>
                      <Typography variant="smallText" color="red">
                        Please confirm you are not a robot
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box>
              {isDesktop && (
                <Box marginTop="110px" marginRight="100px">
                  <CollectedData />
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Appointment;
