import React, { useContext } from "react";
import { Card, Typography, Grid, Theme } from "@mui/material";
import {
  makeStyles,
  useTheme,
} from "@mui/styles";
import { CustomerDataContext } from "../Context/CustomerContext";
import moment from "moment";

export interface IAppProps {}

const useStyles = makeStyles((theme: Theme) =>
  ({
    root: {
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(2),
      width: 308,
    },
  })
);

const ManageCollectedData: React.FC = () => {
  const customerData = useContext(CustomerDataContext);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
      <Card className={classes.root} elevation={0}>
        <Grid container={true} flexDirection="column" paddingBottom="10px">
          <Typography variant="bodyBold">Site ID</Typography>
          <Typography variant="bodyRegular"> {customerData.SiteID} </Typography>
        </Grid>
        <Grid container={true} flexDirection="column" paddingBottom="10px">
          <Typography variant="bodyBold">Site Address</Typography>
          <Typography variant="bodyRegular">
            {" "}
            we dont get this from client nor backend{" "}
          </Typography>
        </Grid>
        <Grid container={true} flexDirection="column" paddingBottom="10px">
          <Typography variant="bodyBold">Meter Location</Typography>
          <Typography variant="bodyRegular">
            {" "}
            we dont get this from client nor backend{" "}
          </Typography>
        </Grid>
        <Grid container={true} flexDirection="column" paddingBottom="10px">
          <Typography variant="bodyBold">Relight Required</Typography>
          <Typography variant="bodyRegular">
            {" "}
            {customerData.customerDetails.isRelightRequired}{" "}
          </Typography>
        </Grid>
        {customerData.appointment.arrivalDateTime !== "" && (
          <Grid container={true} flexDirection="column" paddingBottom="10px">
            <Typography variant="bodyBold">Service Date and Time</Typography>
            <Typography variant="bodyRegular">
              {" "}
              {moment(customerData.appointment.arrivalDateTime).format("LL") +
                " between " +
                customerData.appointment.completionDateTime}{" "}
            </Typography>
          </Grid>
        )}
      </Card>
    </div>
  );
};

export default ManageCollectedData;
