import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";

import licenseData from "../Licenses/licenses.json";

interface IProps {
  open: boolean;
  handleClose: () => void;
}

export interface ILicense {
  licenses: string;
  repository: string;
  licenseUrl: string;
  parents: string;
}

const arr = Object.entries(licenseData);

console.log(arr);
console.log({ hello: "hello" });

const Attributions = (props: IProps): JSX.Element => {
  const { open, handleClose } = props;
  return (
    <Box sx={{ width: "800px" }}>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="bodyBold">3rd Party Attributions</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              <Box sx={{ paddingTop: "16px" }}>
                {arr.map((lic) => {
                  return (
                    <Box sx={{ paddingBottom: "16px" }}>
                      <Box>
                        <Typography
                          variant="bodyRegular"
                          sx={{
                            fontWeight: "600",
                            textDecoration: "underline",
                          }}
                        >
                          {lic[0]}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="bodyRegular">
                          Licenses: {lic[1].licenses}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="bodyRegular">
                          Repository: {lic[1].repository}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="bodyRegular">
                          LicenseUrl: {lic[1].licenseUrl}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="bodyRegular">
                          Parents: {lic[1].parents}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box sx={{ paddingTop: "16px" }}>
              <Typography variant="bodyRegular"></Typography>
            </Box>
            <Box sx={{ paddingTop: "16px" }}>
              <Typography variant="bodyRegular"></Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Attributions;
