import React, { createContext, useState } from "react";

//DONT NEED
interface IProps {
  children?: React.ReactNode;
}

interface IAddress {
  address: string;
  meterLocation: string;
}

interface ISiteDetail {
  siteID: string;
  address: IAddress;
}

interface ISiteAction {
  setSiteDetail: React.Dispatch<React.SetStateAction<ISiteDetail>>;
}

const SiteActionContext = createContext<ISiteAction>({
  setSiteDetail: useState,
});

const SiteDataContext = createContext<ISiteDetail>({} as ISiteDetail);

export const defaultAddress = {
  siteID: "",
  address: {
    address: "12312 99 Ave, Edmonton, AB, T5T5T5",
    meterLocation: "Outside House",
  },
} as ISiteDetail;

const MeterProvider: React.FC<IProps> = (props: IProps) => {
  const [siteDetail, setSiteDetail] = useState<ISiteDetail>(defaultAddress);

  return (
    <SiteActionContext.Provider value={{ setSiteDetail }}>
      <SiteDataContext.Provider value={siteDetail}>
        {props.children}
      </SiteDataContext.Provider>
    </SiteActionContext.Provider>
  );
};

export { SiteActionContext, SiteDataContext };

export default MeterProvider;
