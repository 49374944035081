import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IAppointmentList, IDayTimeMatrix } from "../Context/Types";

import React, { useContext } from "react";
import SelectionCard from "./SelectionCard";
import {
  CustomerActionContext,
  CustomerDataContext,
} from "../Context/CustomerContext";
import useReadableTime from "../Hooks/useReadableTime";

const useStyle = makeStyles((theme) => ({
  timeCard: {
    display: "flex",
    marginBottom: "8px",
    [theme.breakpoints.only("mobile")]: {
      width: "100%",
    },
  },
}));

interface IProps {
  data: IDayTimeMatrix;
  uniqueID: string;
}

const formatAppointmentTime = (data: string) => {
  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const year = data.substring(0, 4);
  const month = data.substring(5, 7);
  const monthWord = monthArray[parseInt(month, 10) - 1];
  const day = data.substring(8, 10);
  const outputString = `${monthWord}.${day} / ${year}`;
  return outputString;
};

const AppointmentGrid: React.FC<IProps> = (props: IProps) => {
  const { data, uniqueID } = props;
  const classes = useStyle();
  const { returnReadableTime } = useReadableTime();
  const savedData = useContext(CustomerDataContext);
  const actions = useContext(CustomerActionContext);

  const onClick = (appointment: IAppointmentList | undefined): void => {
    if (appointment !== undefined) {
      actions.setCustomerData({
        ...savedData,
        appointment: appointment,
        confirmationCode: uniqueID,
      });
    }
  };

  return (
    <Box
      sx={{
        marginLeft: "0px",
        width: "100%",
        textAlign: "center",
        marginTop: "32px",
      }}
    >
      <Box sx={{ backgroundColor: "#0057B8" }}>
        <Typography
          variant="bodyBold"
          sx={{ marginTop: "8px", color: "white" }}
        >
          {formatAppointmentTime(data.day)}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
        {data.appointments.map((time) => (
          <Box className={classes.timeCard}>
            {returnReadableTime(time.arrivalDateTime.substr(-8, 5)) !==
              "0:00 AM" &&
              returnReadableTime(time.completionDateTime.substr(-8, 5)) !==
                "0:00 AM" && (
                <SelectionCard
                  selected={savedData.appointment === time}
                  data={time}
                  onClick={onClick}
                />
              )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AppointmentGrid;
