import React, { createContext } from "react";
import {
  IAppointmentBookRequest,
  ICustomerData,
  ICustomerDetails,
  IDefaultCustomerData,
  IMakeBookingRequest,
  ISiteSearchByIdResult,
} from "./Types";
import SiteContent from "../SiteContent/SiteContent";

interface IProps {
  children?: React.ReactNode;
}

interface ICustomerAction {
  setCustomerData: React.Dispatch<React.SetStateAction<IMakeBookingRequest>>;
}

const CustomerDataContext = createContext<IMakeBookingRequest>(
  {} as IMakeBookingRequest
);

const CustomerActionContext = createContext<ICustomerAction>({
  setCustomerData: React.useState,
} as ICustomerAction);

export const defaultData = {
  confirmationCode: "",
  SiteID: "",
  acceptedRecaptcha: false,
  appointmentBookRequest: {
    postal: "",
    spId: "",
    source: "CSS",
    taskType: "EXCH-MTR",
    startDateTime: "",
    endDateTime: "",
  } as IAppointmentBookRequest,
  customerDetails: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    email: "",
    isDog: "",
    isRelightRequired: "",
  } as ICustomerDetails,
  appointment: {
    appointmentWindowDateTime: "",
    appointmentWindowDuration: "",
    arrivalDateTime: "",
    completionDateTime: "",
    rank: "",
    shiftId: "",
    units: "",
  },
  reminders: {
    isCall: false,
    isEmail: false,
  },
} as IMakeBookingRequest;

const CustomerProvider: React.FC<IProps> = (props: IProps) => {
  const [customerData, setCustomerData] = React.useState<IMakeBookingRequest>(
    defaultData
  );
  return (
    <CustomerDataContext.Provider value={customerData}>
      <CustomerActionContext.Provider value={{ setCustomerData }}>
        {props.children}
      </CustomerActionContext.Provider>
    </CustomerDataContext.Provider>
  );
};

export { CustomerActionContext, CustomerDataContext };

export default CustomerProvider;
