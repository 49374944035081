import React from "react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import Navbar from "./Navbar";
import Attributions from "../Pages/Attributions";

interface IProps {
  children?: React.ReactNode;
}

const Layout: React.FC<IProps> = (props: IProps) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid
        container={true}
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        wrap="nowrap"
        minHeight="100vh"
      >
        <Grid item={true} container={true} flex={1} maxHeight="96px">
          <Navbar />
        </Grid>
        <Grid
          item={true}
          container={true}
          marginTop="80px"
          marginBottom="32px"
          justifyContent="center"
        >
          {props.children}
        </Grid>
        <Grid
          item={true}
          flex={1}
          marginTop="auto"
          container={true}
          bottom={0}
          minHeight="80px"
          maxHeight="72px"
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          {/* <AppBar color="primary" position="fixed" style={{ top: 'auto' }}>
            <Toolbar />
          </AppBar> */}
          <Box
            sx={{
              display: "flex",
              paddingTop: "0px",
              justifyContent: "space-between",
              paddingRight: "80px",
              paddingLeft: "80px",
            }}
          >
            <Button onClick={handleClickOpen}>
              <Typography
                noWrap={true}
                sx={{
                  fontFamily: "open sans",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16.41px",
                  color: "white",
                  textDecoration: "underline",
                }}
              >
                ATTRIBUTIONS
              </Typography>
            </Button>
            <Attributions open={open} handleClose={handleClose} />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Layout;
