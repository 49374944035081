import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SiteContent from "../SiteContent/SiteContent";
import {
  IAppointmentBookRequest,
  ISiteSearchByIdResult,
  ISiteIdData,
  IGetSiteID,
} from "../Context/Types";
import useLoading from "../Hooks/useLoading";
import useHomeInspection from "../Hooks/useHomeInspection";
import {
  CustomerActionContext,
  CustomerDataContext,
} from "../Context/CustomerContext";
import { Formik } from "formik";
import { validationSchemaSiteID } from "../Context/ValidationSchema";
import { firebaseAnalytics, firebaseLogEvent } from "../API/firebase";

const useStyle = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    spacing: "8px",
    flexBasis: "72%",
    [theme.breakpoints.only("desktop")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
    [theme.breakpoints.only("tablet")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    [theme.breakpoints.only("mobile")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  innerInput: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "21.79px",
    maxLength: 13,
    minLength: 13,
  },
}));

const Welcome: React.FC = () => {
  const history = useHistory();
  const classes = useStyle();
  const { searchSiteId } = useHomeInspection();
  const { showLoading, hideLoading, loading } = useLoading();
  const actions = useContext(CustomerActionContext);
  const data = useContext(CustomerDataContext);
  const [siteIdData, setSiteIdData] = useState<ISiteIdData>({
    id: "",
    error: false,
    valid: true,
  } as ISiteIdData);
  const [attempts, setAttempts] = useState<number>(0);

  const onInputChange = () => {
    //const validSiteId = isSiteIdValid(siteID);
    setSiteIdData({ ...siteIdData, error: false, valid: true });
  };
  const setMapSiteIdInfo = (values: ISiteSearchByIdResult): void => {
    const appointmentBookRequest = {
      taskType: values.data.taskType,
      startDateTime: data.appointmentBookRequest.startDateTime,
      endDateTime: data.appointmentBookRequest.endDateTime,
      postal: values.data.postal,
      source: data.appointmentBookRequest.source,
      spId: values.data.spId,
    } as IAppointmentBookRequest;

    actions.setCustomerData({
      ...data,
      SiteID: values.data.SiteID,
      appointmentBookRequest: appointmentBookRequest,
    });
  };

  const onSubmitID = async (values: IGetSiteID): Promise<void> => {
    try {
      showLoading();
      const firebaseData = await searchSiteId(values.SiteID);
      setMapSiteIdInfo(firebaseData);

      const returnData = {
        SiteID: firebaseData.data.SiteID,
        taskType: firebaseData.data.taskType,
        spId: firebaseData.data.spId,
        postal: firebaseData.data.postal,
        SOStatus: firebaseData.data.SOStatus,
        errMsg: firebaseData.data.errMsg,
        errMsgCd: firebaseData.data.errMsgCd,
      };
      if (returnData.SOStatus === "Communication In Progress") {
        firebaseLogEvent(firebaseAnalytics, "valid_siteID_entered");
        firebaseLogEvent(firebaseAnalytics, "customerInformation_page_visited");
        history.push("/meter-exchange/customer-information");
      } else {
        console.log("error submitting siteID");
      }
    } catch (error) {
      console.log("error", error);
      setSiteIdData({ ...siteIdData, error: true } as ISiteIdData);
      setAttempts(attempts + 1);
      firebaseLogEvent(firebaseAnalytics, "invalid_siteID_entered");
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    firebaseLogEvent(firebaseAnalytics, "welcome_page_visited");
  }, []);

  return (
    <>
      {loading !== null && loading}
      {loading === null && (
        <Box className={classes.root}>
          <Formik
            initialValues={data}
            onSubmit={(values) => {
              onSubmitID(values);
            }}
            validationSchema={validationSchemaSiteID}
            validateOnMount={true}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
          >
            {(formikProps) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                isValid,
                handleSubmit,
              } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  <Box>
                    {attempts < 3 && (
                      <Box>
                        <Grid item={true}>
                          <Typography variant="h1">
                            {SiteContent.welcome.title}
                          </Typography>
                        </Grid>
                        <Grid item={true}>
                          <Typography variant="sidebarRegular">
                            {SiteContent.welcome.titleSmall}
                          </Typography>
                        </Grid>

                        <Box>
                          <Box sx={{ paddingTop: "24px" }}>
                            <Typography variant="bodyBold">Site ID</Typography>
                          </Box>
                          <Box>
                            <Box sx={{ paddingTop: "8px" }}>
                              <Grid item={true} container={true}>
                                <TextField
                                  id="siteIdInput"
                                  autoComplete="off"
                                  placeholder="Please enter your Site ID"
                                  variant="outlined"
                                  name="SiteID"
                                  value={values.SiteID}
                                  error={!!errors.SiteID && !!touched.SiteID}
                                  fullWidth
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  helperText={
                                    <Typography
                                      variant="smallText"
                                      color="red"
                                      marginLeft="-14px"
                                    >
                                      {touched.SiteID ? errors.SiteID : ""}
                                    </Typography>
                                  }
                                  InputProps={{
                                    classes: {
                                      root: classes.innerInput,
                                    },
                                  }}
                                />
                              </Grid>
                              {siteIdData.valid === false &&
                                siteIdData.id.length > 0 && (
                                  <Box sx={{ marginTop: "-24px" }}>
                                    <Typography variant="smallText" color="red">
                                      ID must starts with 0001 or 0003, and be
                                      13 digits long
                                    </Typography>
                                  </Box>
                                )}
                              {siteIdData.error && (
                                <Box sx={{ marginTop: "-24px" }}>
                                  <Typography variant="smallText" color="red">
                                    Error: could not find your site ID
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                          <Box marginTop="8px">
                            <Button
                              variant="main"
                              size="large"
                              disabled={false}
                              type="submit"
                              onClick={() => {
                                onInputChange();
                              }}
                            >
                              <Typography variant="h2">
                                {" "}
                                {SiteContent.welcome.primaryButton}
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </form>
              );
            }}
          </Formik>
          {attempts >= 3 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "10px",
              }}
            >
              <Box>
                <Typography variant="h1">
                  Don't Remember Your Site ID?
                </Typography>
              </Box>
              <Box>
                <Typography variant="sidebarRegular">
                  For assistance with your site ID, please contact our support
                  team at 310-5678.
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default Welcome;
