import React from 'react';
import { makeStyles } from '@mui/styles';
import CallIcon from '@mui/icons-material/Call';
import { AppBar, Box, Toolbar, Typography, useTheme, Theme } from '@mui/material';
import useResponsiveHelper from '../Hooks/useResponsiveHelper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
      backgroundColor: theme.palette.primary.main,
      paddingTop: theme.spacing(2),
      justifyContent: 'space-between',
      width: '100%',
  },
  logoImageContainer: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(8),
      alignItems: 'flex-start',
      display: 'inline-flex',
      [theme.breakpoints.down('tablet')]: {
          paddingLeft: theme.spacing(0),
      },
  },
  logoImage: {
      height: theme.spacing(5),
      alignSelf: 'flex-start',
      objectFit: 'contain',
      marginBottom: theme.spacing(1),
  },
  titleAndNumberContainer: {
      justifyContent: 'flex-end',
      alignItems: 'center',
      display: 'flex',
      paddingRight: theme.spacing(9),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.only('tablet')]: {
          display: 'flex',
          flexDirection: 'column',
          paddingRight: theme.spacing(9),
          paddingLeft: '16px',
          alignItems: 'flex-end',
      },
      [theme.breakpoints.down('tablet')]: {
          display: 'flex',
          flexDirection: 'column',
          paddingRight: theme.spacing(0),
          paddingLeft: '16px',
          alignItems: 'flex-end',
      },
  },
  dividerContainer: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      height: '40%',
  },
}));

const NavBar: React.FC = () => {
  const { isDesktop } = useResponsiveHelper();
  const classes = useStyles();
  const theme = useTheme();
  const lowestScreenSize = 784;

  return (
    <AppBar position="fixed" elevation={0} style={{ maxHeight: '96px' }}>
      <Toolbar className={classes.root}>
        <Box className={classes.logoImageContainer}>
          <img className={classes.logoImage} src="/images/VersionWhiteLogo@1x.png" title="ATCO logo" />
        </Box>
        <Box className={classes.titleAndNumberContainer}>
          <Typography variant="h2">Meter Exchange</Typography>
          {isDesktop && (
                        <Box className={classes.dividerContainer}>
                            <hr style={{ borderColor: 'white', height: '24px' }} />
                        </Box>
                    )}
              <Box sx={{display:'flex'}}>
                <CallIcon sx={{width:'41px',paddingRight:'8px'}}/>
                <Typography variant="h2">310-5678</Typography>
              </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
