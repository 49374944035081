import React from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import BaseTheme from "./Themes/ThemeProvider";
import Layout from "./Components/Layout";

import { BrowserRouter as Router, Route } from "react-router-dom";
import Routes from "./Routes/Routes";

import "./App.css";
import MeterProvider from "./Context/MeterContext";
import CustomerProvider from "./Context/CustomerContext";
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// pick a date util library
// port MomentUtils from "@date-io/moment";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";

const App: React.FC = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6Lex57IbAAAAANJe1bucrR_geRG-OwMulWJcrjyq"
      language="English (US)"
      useRecaptchaNet={true}
      useEnterprise={true}
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <ThemeProvider theme={BaseTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <MeterProvider>
              <CustomerProvider>
                <Layout>
                  <Route component={Routes} />
                </Layout>
              </CustomerProvider>
            </MeterProvider>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  );
};

export default App;
