import React, { useState } from 'react';

interface IConvertTime {
    returnReadableTime: (timeString: string) => string;
}

const useReadableTime = (): IConvertTime => {

    const returnReadableTime = (timeString: string): string => {
        let displayTime = '';
        if (timeString!=null) { // If time format correct
          const hours = Number(timeString.substr(0,2));
          const minutes = timeString.substr(3,2);
          if( hours>12){
            const newHours = hours-12;
            displayTime = newHours + ":"+minutes+" PM"
            return (displayTime )
          }else{
            displayTime = hours + ":" + minutes+" AM"
          }
        }
        return displayTime; // return adjusted time or original string
    };


    return { returnReadableTime };
};

export default useReadableTime;
