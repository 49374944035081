import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  CircularProgress
} from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  CustomerActionContext,
  CustomerDataContext,
} from "../Context/CustomerContext";
import { SiteDataContext } from "../Context/MeterContext";
import SiteContent from "../SiteContent/SiteContent";
import moment from "moment";

interface Props { }

const ManageThankyou: React.FC = (props: Props) => {
  const customerData = useContext(CustomerDataContext);
  const siteData = useContext(SiteDataContext);
  const history = useHistory();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        history.replace('/');
      }

    };
  }, [history])


  return (
    <>
      {
        loading ?
          (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '100vh' }}
            >
              <Grid item spacing={1}>
                <CircularProgress thickness={7} size={100} />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container={true}
              item={true}
              direction="column"
              spacing={1}
              paddingLeft="80px"
              paddingRight="80px"
              flexBasis="72%"
              justifyContent="center"
            >
              <Grid item={true}>
                <Typography variant="h1">{SiteContent.Thankyou.title}</Typography>
              </Grid>
              <Grid item={true}>
                <Typography variant="sidebarRegular">
                  {SiteContent.Thankyou.subTitle}
                </Typography>
              </Grid>
              <Grid marginTop="20px">
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Confirmation Number</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      confirmationID
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Arrival Time</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      {" "}
                      {moment(customerData.appointment.arrivalDateTime).format("LL") +
                        " between " +
                        customerData.appointment.completionDateTime}{" "}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Appointment Type</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Meter Replacement</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Site ID</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">{customerData.SiteID}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Service Address</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      No Address
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Meter Location</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      meter location
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Relight Required?</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      {customerData.customerDetails.isRelightRequired}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">First Name</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      {customerData.customerDetails.firstName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Last Name</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      {customerData.customerDetails.lastName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Phone Number</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      {customerData.customerDetails.phoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      Alternate Phone Number
              </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      {customerData.customerDetails.alternatePhoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">Email Address</Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      {customerData.customerDetails.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="10px"
                >
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      Is there a dog in the yard/ on the property?
              </Typography>
                  </Grid>
                  <Grid item={true}>
                    <Typography variant="bodyRegular">
                      {customerData.customerDetails.isDog}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  marginTop="20px"
                  flexDirection="row"
                  justifyContent="center"
                >
                  <Button variant="main" size="large">
                    <Typography>PRINT A COPY</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )
      }
    </>
  );
};

export default ManageThankyou;