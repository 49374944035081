import React, { useContext, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Typography, Button, Theme, Box, Grid } from "@mui/material";
import {
  CustomerActionContext,
  CustomerDataContext,
} from "../Context/CustomerContext";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import { validationSchemaCustomer } from "../Context/ValidationSchema";
import SiteContent from "../SiteContent/SiteContent";
import { ICustomerDetails, IReminders } from "../Context/Types";
import useLoading from "../Hooks/useLoading";
import CollectedData from "../Components/CollectedData";
import CustomerForm from "../Components/CustomerForm";
import useResponsiveHelper from "../Hooks/useResponsiveHelper";
import { firebaseAnalytics, firebaseLogEvent } from "../API/firebase";

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.only("desktop")]: {
      paddingLeft: "80px",
      paddingRight: "80px",
    },
    [theme.breakpoints.only("tablet")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    [theme.breakpoints.only("mobile")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  innerInput: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "21.79px",
  },
  errorBorder: {
    border: `1px solid ${theme.palette.error}`,
  },
}));

const CustomerInformation: React.FC = () => {
  const classes = useStyle();
  const history = useHistory();
  const data = useContext(CustomerDataContext);
  const actions = useContext(CustomerActionContext);
  const { isMobileOrTablet, isDesktop } = useResponsiveHelper();
  const { loading } = useLoading();
  const { pathname } = useLocation();

  const [formState, setFormState] = useState<ICustomerDetails>(
    data.customerDetails
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const mappedCustomerInfoData = (values: ICustomerDetails): void => {
    const customerInfo = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      alternatePhoneNumber: values.alternatePhoneNumber,
      isDog: values.isDog,
      isRelightRequired: values.isRelightRequired,
    } as ICustomerDetails;

    const reminderInfo = {
      isCall: data.reminders.isCall || false,
      isEmail: data.reminders.isEmail || false,
    } as IReminders;

    actions.setCustomerData({
      ...data,
      customerDetails: customerInfo,
      reminders: reminderInfo,
    });
  };

  return (
    <>
      {loading !== null && loading}
      {loading === null && (
        <Formik
          initialValues={data.customerDetails}
          onSubmit={(values) => {
            mappedCustomerInfoData(values);
            firebaseLogEvent(firebaseAnalytics, "appointment_page_visited");
            history.push("/meter-exchange/customer-appointment");
          }}
          validationSchema={validationSchemaCustomer}
          validateOnMount={true}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
        >
          {(formikProps) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Box className={classes.root}>
                  <Box
                    sx={{ display: "flex", alignItems: "flex-start", mb: 3 }}
                  >
                    <Box sx={{ pl: 4, pr: 4 }}>
                      <CustomerForm
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Box>
                    {isDesktop && (
                      <Grid
                        container
                        item={true}
                        marginTop="100px"
                        flexBasis="28%"
                      >
                        <CollectedData />
                      </Grid>
                    )}
                  </Box>
                  {isMobileOrTablet && (
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ marginLeft: "24px" }}>
                        <Typography variant="bodyBold"> Other Info</Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          paddingLeft: "24px",
                          paddingBottom: "32px",
                          paddingRight: "32px",
                        }}
                      >
                        <CollectedData />
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignSelf: "flex-start",
                      pl: 4,
                      pr: 4,
                    }}
                  >
                    <Box sx={{ mr: 1 }}>
                      <Button
                        variant="submain"
                        size="large"
                        onClick={() => {
                          history.push("/");
                        }}
                      >
                        <Typography variant="h2">
                          {SiteContent.CustomerInformation.secondaryButton}
                        </Typography>
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="main"
                        size="large"
                        disabled={false}
                        type="submit"
                      >
                        <Typography variant="h2">
                          {" "}
                          {SiteContent.CustomerInformation.primaryButton}
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default CustomerInformation;
