import React, { useContext, useState } from "react";
import { Card, Typography, Grid, Theme, useTheme } from "@mui/material";
import {
  makeStyles,
} from "@mui/styles";
import { CustomerDataContext } from "../Context/CustomerContext";
import useReadableTime from "../Hooks/useReadableTime";
import moment from "moment";
import { IAppProps } from "./ManageCollectedData";

const useStyles = makeStyles((theme: Theme) =>
  ({
    root: {
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(2),
      [theme.breakpoints.up('desktop')]: {
        width: 308,
      },
      [theme.breakpoints.down('tablet')]: {
        width:'100%',
      },
    },
  })
);

export default function CollectedData(props: IAppProps) {
  const customerData = useContext(CustomerDataContext);
  const { returnReadableTime } = useReadableTime();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div>
      <Card className={classes.root} elevation={0}>   
        <Grid container={true} flexDirection="column" paddingBottom="20px" paddingTop='10px' >
          <Typography variant="bodyBold">Site ID</Typography>
          <Typography variant="bodyRegular"> {customerData.SiteID} </Typography>
        </Grid>
        {/* <Grid container={true} flexDirection="column" paddingBottom="10px">
          <Typography variant="bodyBold">Site Address</Typography>
          <Typography variant="bodyRegular">
            {" "}
            {siteData.address.address}{" "}
          </Typography>
          <Typography
            variant="smallText"
          >
            Not your Address? Call us at 
            <Typography
             variant="smallText"
             color="primary">
               {'  '}
             310-5678
            </Typography>
          </Typography>
        </Grid> */}
        {/* <Grid container={true} flexDirection="column" paddingBottom="10px">
          <Typography variant="bodyBold">Meter Location</Typography>
          <Typography variant="bodyRegular">
            {" "}
            {siteData.address.meterLocation}{" "}
          </Typography>
        </Grid> */}
        {customerData.appointment.arrivalDateTime !== "" && (
          <Grid container={true} flexDirection="column" paddingBottom="20px">
            <Typography variant="bodyBold">Service Date and Time</Typography>
            <Typography variant="bodyRegular">
              {" "}
              {moment(customerData.appointment.arrivalDateTime).format("LL") +
                " between " +
                returnReadableTime(customerData.appointment.arrivalDateTime.substr(-8,5))
                + " and " +
                returnReadableTime(customerData.appointment.completionDateTime.substr(-8,5))
                }
               
            </Typography>
          </Grid>
        )}
        {customerData.customerDetails.firstName !=="" && (
          <Grid>
            <Grid
              container
              style={{ backgroundColor: theme.palette.grey[300] }}
              paddingLeft={theme.spacing(1)}
              paddingRight={theme.spacing(1)}
              height="1px"
            ></Grid>
            <Grid
              container={true}
              flexDirection="column"
              paddingBottom="20px"
              paddingTop="20px"
            >
              <Typography variant="bodyBold">First Name</Typography>
              <Typography variant="bodyRegular">
                {" "}
                {customerData.customerDetails.firstName}{" "}
              </Typography>
            </Grid>
            <Grid container={true} flexDirection="column" paddingBottom="20px">
              <Typography variant="bodyBold">Last Name</Typography>
              <Typography variant="bodyRegular">
                {" "}
                {customerData.customerDetails.lastName}{" "}
              </Typography>
            </Grid>
            <Grid container={true} flexDirection="column" paddingBottom="20px">
              <Typography variant="bodyBold">Phone Number</Typography>
              <Typography variant="bodyRegular">
                {" "}
                {customerData.customerDetails.phoneNumber}{" "}
              </Typography>
            </Grid>
            {customerData.customerDetails.alternatePhoneNumber !== "" && (
              <Grid
                container={true}
                flexDirection="column"
                paddingBottom="20px"
              >
                <Typography variant="bodyBold">
                  Alternate Phone Number
                </Typography>
                <Typography variant="bodyRegular">
                  {" "}
                  {customerData.customerDetails.alternatePhoneNumber}{" "}
                </Typography>
              </Grid>
            )}
            {customerData.customerDetails.email !== "" && (
              <Grid
                container={true}
                flexDirection="column"
                paddingBottom="20px"
              >
                <Typography variant="bodyBold">Email</Typography>
                <Typography variant="bodyRegular">
                  {" "}
                  {customerData.customerDetails.email}{" "}
                </Typography>
              </Grid>
            )}
            <Grid container={true} flexDirection="column" paddingBottom="20px">
              <Typography variant="bodyBold">Dog on the property?</Typography>
              <Typography variant="bodyRegular">
                {" "}
                {customerData.customerDetails.isDog}{" "}
              </Typography>
            </Grid>
            <Grid container={true} flexDirection="column" paddingBottom="10px">
            <Typography variant="bodyBold">Relight Required</Typography>
            <Typography variant="bodyRegular">
              {" "}
              {customerData.customerDetails.isRelightRequired}{" "}
            </Typography>
          </Grid>
          </Grid>
        )}
      </Card>
    </div>
  );
}
