import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDWVQ8QVHIfoB_A6mCKYBzjm7q9i7RTcb0",
  authDomain: "gas-meter-replacement.firebaseapp.com",
  projectId: "gas-meter-replacement",
  storageBucket: "gas-meter-replacement.appspot.com",
  messagingSenderId: "777129705197",
  appId: "1:777129705197:web:9a1212f2c57a3b05f096e4",
  measurementId: "G-ZQTFKJ52NC",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const functions = getFunctions(app);
export const anonSignIn = async () => signInAnonymously(auth);
export const firebaseAnalytics = getAnalytics();
export const firebaseLogEvent = logEvent;
