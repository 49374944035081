import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import Welcome from "../Pages/Welcome";
import CustomerInformation from "../Pages/CustomerInformation";
import Appointment from "../Pages/Appointment";
import Thankyou from "../Pages/Thankyou";
import ManageWelcome from "../Pages/ManageWelcome";
import ManageAppointment from "../Pages/ManageAppointment";
import ManageThankyou from "../Pages/ManageThankyou";

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact={true} path="/" component={Welcome} />
      <Route exact={true} path="/manage-booking" component={ManageWelcome} />
      <Route
        exact={true}
        path="/meter-exchange/customer-information"
        component={CustomerInformation}
      />
      <Route
        exact={true}
        path="/meter-exchange/customer-appointment"
        component={Appointment}
      />
      <Route
        exact={true}
        path="/manage-booking/customer-appointment"
        component={ManageAppointment}
      />
      <Route
        exact={true}
        path="/manage-booking/thank-you"
        component={ManageThankyou}
      />
      <Route
        exact={true}
        path="/meter-exchange/thank-you"
        component={Thankyou}
      />
    </Switch>
  );
};

export default Routes;
