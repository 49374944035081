import * as yup from "yup";

export const validationSchemaCustomer = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  phoneNumber: yup
    .string()
    .matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      "Please enter a valid Phone Number"
    )
    .required("Phone number is required"),
  alternatePhoneNumber: yup
    .string()
    .matches(
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
      "Please enter a valid Phone Number"
    ),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Please enter a valid email address"),
  isDog: yup.string().required("Required field"),
  isRelightRequired: yup.string().required("Required field"),
  // MeterPlacement: yup.string().required('Required field'),
});

export const validationSchemaSiteID = yup.object({
  SiteID: yup
    .string()
    .required("Site ID is required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(13, "ID must be exactly 13 digits")
    .max(13, "ID must be exactly 13 digits")
    .test(
      "oneOfRequired",
      "ID must starts with 0001 or 0003",
      (val) => val?.substr(0, 4) === "0001" || val?.substr(0, 4) === "0003"
    ),
});
